<template>
  <div>
    <span
      v-for="(folder, index) in folderLineage"
      :key="`folder_breadcrumb_${index}`"
    >
      <span
        class="body-2 primary--text clickable"
        @click="$emit('goToFolder', index)"
      >
        {{ folder.name }}
      </span>
      <span
        v-if="index < folderLineage.length - 1"
        class="caption grey--text text--darken-2 mx-2"
      >
        /
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    currentFolder: {
      type: Object,
      required: true
    },
    folderLineage: {
      type: Array,
      required: true
    }
  }
}
</script>