<template>
  <v-row justify="start" class="height100">
    <v-col class="py-0 px-1 height100">
      <SnackBar
        :snackMsg="snackMsg"
        :snackOn="snackOn"
        @close="snackOn = false"
      />

      <NewFolder
        :newFolder="newFolder"
        :edit="editFolder"
        @addFolder="addFolder"
        @updateFolder="updateFolder"
        @close="close"
      />

      <v-card
        outlined
        rounded="xl"
        :color="navColor"
        :style="`
          height: 100%;
          width: 100%;
          border: ${borderStyle};
          overflow: hidden;
        `"
      >
        <v-container class="py-2 height100">
          <v-row
            align="center"
            justify="start"
            class="px-1 pr-2"
          >
            <v-btn
              icon
              :disabled="this.from === '/'"
              @click="routeBack"
            >
              <v-icon>
                {{ mdiArrowLeft }}
              </v-icon>
            </v-btn>

            <div class="breadcrumbs-container">
              <v-breadcrumbs class="py-0 breadcrumb" :items="breadcrumbItems">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                    :class="{
                      'clickable font-weight-bold': true,
                      'primary--text': !$vuetify.theme.dark,
                      'tertiary--text': $vuetify.theme.dark,
                    }"
                    @click="$router.replace(item.to)"
                    @mouseover="onBreadcrumbHover(item)"
                    @mouseleave="onBreadcrumbLeave"
                  >
                    {{ item.text.slice(0, 15) + (item.text.length > 15 ? '...' : '') }}
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </div>

            <v-spacer />

            <v-btn
              outlined
              :color="$vuetify.theme.dark ? 'tertiary' : '#6200EA'"
              :style="`border-radius: 12px;`"
              @click="newFolder = true"
            >
              new folder
            </v-btn>

            <div class="mx-1"></div>

            <v-btn
              text
              :disabled="!currentFolder"
              :color="$vuetify.theme.dark ? 'tertiary' : '#6200EA'"
              :style="`border-radius: 12px;
              background-color: ${
                $vuetify.theme.dark
                  ? 'rgba(255, 191, 16, 0.23)'
                  : 'rgba(125, 33, 237, 0.157);'
              }`"
              @click="editFolder = true"
            >
              edit
            </v-btn>

            <div class="mx-1"></div>

            <v-btn
              v-if="currentFolder && currentFolder.sender === myProfileId"
              icon
              :disabled="!currentFolder"
              :color="'red'"
              :style="`border-radius: 12px;
              background-color: rgba(255, 0, 0, 0.32)`"
              @click="openDeleteModalToggle"
              
            >
              <v-icon >
                {{ mdiDelete }}
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="openDeleteModal"
              :max-width="300"
              @click:outside="onClickOutside"
            >
              <v-card rounded="lg">
                <v-container>
                  <v-row justify="center" class="py-3">
                    Are you sure?
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      @click="openDeleteModal = false"
                      color="primary"
                      class="hs-rounded-12"
                    >
                      cancel
                    </v-btn>

                    <div class="mx-1"></div>

                    <v-btn
                      text
                      color="red"
                      class="hs-rounded-12"
                      @click="onDeleteFolder"
                    >
                      delete
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="openDeleteContentModal"
              :max-width="300"
              @click:outside="onClickOutside"
            >
              <v-card rounded="lg">
                <v-container>
                  <v-row justify="center" class="py-3">
                    Are you sure?
                  </v-row>
                  <v-row justify="center">
                    <v-btn
                      @click="openDeleteContentModal = false;
                        contentIdToDelete = null;"
                      color="primary"
                      class="hs-rounded-12"
                    > 
                      cancel
                    </v-btn>

                    <div class="mx-1"></div>
                    
                    <v-btn
                      text
                      color="red"
                      class="hs-rounded-12"
                      @click="onDeleteContent"
                    >
                      delete
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-row>
        <!-- </v-container> -->

        <v-fade-transition hide-on-leave>
          <v-container v-if="loading" fill-height>
            <v-row justify="center" align="center">
              <v-progress-circular
                indeterminate
                color="primary"
              />   
            </v-row>
          </v-container>
        </v-fade-transition>

        <v-fade-transition hide-on-leave>
          <ScrollPagination
            :requestData="getPaginationRequestData('contents')"
            :countData="getPaginationCountData('contents')"
            :customPagination="{
              limit: 20,
              skip: 0,
              pageSize: 20,
            }"
            :setFatherItems="setContents"
            :key="contentComponentKey"
            autoHide="never"
            :rootHeight="`${rootHeight}px`"
            rootStyle="align-items: baseline;"
            :entityName="'content'"
          >
            <template v-slot:staticSlot>
              <div style="width: 100%">
                <v-container>
                  <v-row justify="start" class="px-3">
                    <span class="text-overline">
                      Folders
                    </span>
                  </v-row>

                  <v-row v-if="folders.length > 0" justify="start" class="px-3">
                    <v-col
                      v-for="(folder, index) in folders"
                      class="py-0 px-2"
                      sm="6"
                      md="6"
                      lg="4"
                      :key="`col_folder_${index}`"
                    >
                      <!--
                        see https://github.com/SortableJS/Vue.Draggable/issues/743
                        and https://github.com/SortableJS/Sortable/issues/1571
                        we use force-fallback="true" to solve safari losing drag event 
                      -->
                      <draggable
                        :list="folders"
                        :force-fallback="true"
                        :sort="false"
                        @start="onDragStart"
                        @end="onDragEnd"
                      >
                        <v-hover
                          v-slot:default="{ hover }"
                          :key="`folder_${index}`"
                        >
                          <FolderCard
                            :id="`folder_${folder ? folder.id : index}`"
                            class="mb-4 folder"
                            :hover="hover"
                            :dragging="dragging"
                            :folder="folder"
                            @enter="setCurrentFolder"
                          />
                        </v-hover>
                      </draggable>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row justify="start" class="px-3">
                    <span class="text-overline">
                      Contents
                    </span>
                  </v-row>
                                
                  <v-row
                    v-if="folders.length + contents.length === 0"
                    justify="start"
                    class="px-3"
                  >
                    <span :class="{
                      'text-subtitle-2 grey--text': true,
                      'text--lighten-1': $vuetify.theme.dark,
                      'text--darken-1': !$vuetify.theme.dark
                    }">
                      Drag and drop your media here
                    </span>
                  </v-row>
                </v-container>
              </div>
            </template>
            
            <template v-slot:child="props">
              <v-col
                sm="4"
                :key="`col_content_${props.index}`"
              >
                <draggable
                  :list="contents"
                  :force-fallback="true"
                  :sort="false"
                  @start="onDragStart"
                  @end="onDragEnd"
                >
                  <v-hover
                    v-slot:default="{ hover }"
                    :key="`content_${props.index}`"
                  >
                    <ContentCard
                      :id="`content_${props.item.id}`"
                      class="content"
                      :idx="props.index"
                      :content="props.item"
                      :allowEdit="true"
                      type="pdf"
                      :activeContentIdx="props.index"
                      :hover="hover"
                      :dragging="dragging"
                      @setActiveContent="setActiveContent(props.item)"
                    />

                    <!-- 
                      <v-btn
                        v-if="false && props.item.sender === myProfileId"
                        icon
                        @click="handleDeleteContent(props.item.id);"
                      >
                        <v-icon color="red">
                          {{ mdiDelete }}
                        </v-icon>
                      </v-btn> -->
          
                  </v-hover>
                </draggable>
              </v-col>
            </template>
          </ScrollPagination>
          <!-- </div> -->
        </v-fade-transition>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Scrollbar } from '@components/App'
import ScrollPagination from '@components/App/ScrollPagination'
import { FolderCard, NewFolder } from '@components/Folder'
import ContentCard from '@components/Content/Card'
import { SnackBar } from '@components/App'
import { mdiArrowLeft, mdiForward, mdiPencil, mdiDelete } from '@mdi/js'
import draggable from 'vuedraggable';
import { findIndex } from 'lodash'
import ObjectID from 'bson-objectid'
import API from '@api'

/*
* Note that Folders and MyFolders have different purposes.
* "MyFolders" is invariant to folder navigation, while "Folders"
* represent the sub folders of the current folder.
*/

export default {
  components: {
    SnackBar,
    Scrollbar,
    ScrollPagination,
    FolderCard,
    NewFolder,
    ContentCard,
    draggable
  },

  async created() {
    this.loading = true
    // no need to set folders here if there is no query
    // because they are already set in the PersonaPanel
    if (this.$route.query && this.$route.query.id) {
      try {
        const folder = await API().get(`folder/${this.user.id}`, {
          params: {
            id: this.$route.query.id
          }
        })
        this.SET_CURRENT_FOLDER(folder)
        this.resetBreadcrumbs()
        this.addBreadcrumbs(folder)
      } catch(err) {
        this.snackMsg = 'errServer'
        this.snackOn = true
      }
    }
    const requestData = this.getPaginationRequestData('folders')
    const folders = await API().get(requestData.url, { params: requestData.params })
    this.setFolders(folders)
    this.loading = false
  },

  data: () => ({
    mdiDelete,
    mdiPencil,
    mdiForward,
    mdiArrowLeft,
    snackMsg: '',
    snackOn: false,
    loading: false,
    dragging: false,
    newFolder: false,
    editFolder: false,
    breadcrumbItems: [{
      id: null,
      text: 'My Library',
      disabled: false,
      to: '/folders'
    }],
    activeContentIdx: 0,
    contentColumns: [[], [], []],
    breadcrumbHover: null,
    itemDragging: null,
    openDeleteModal: false,
    openDeleteContentModal: false,
    contentIdToDelete: null,
    contentComponentKey: 1,
    folderComponentKey: 1
  }),

  watch: {
    async $route (to, from){
      this.loading = true
      if (to.query.id) {
        const folder = await API().get(`folder/${this.user.id}`, {
          params: {
            id: this.$route.query.id
          }
        })
        this.SET_CURRENT_FOLDER(folder)
      } else {
        this.SET_FOLDERS([])
        this.setContents([])  
        this.SET_CURRENT_FOLDER(null)
      }
      this.resetBreadcrumbs()
      if (this.currentFolder)this.addBreadcrumbs(this.currentFolder)
      const requestData = this.getPaginationRequestData('folders')
      const folders = await API().get(requestData.url, { params: requestData.params })
      this.setFolders(folders)
      this.loading = false
    },

    contents: {
      handler(newContents) {
        this.contentColumns = [[], [], []];
        newContents.forEach((content, idx) => {
          const colIndex = idx % 3;
          this.contentColumns[colIndex].push(content);
        });
      },
      immediate: true,
    }
  },

  computed: {
    ...mapGetters({
      innerHeight: 'innerHeight',
      user: 'user',
      myProfileId: 'profile/id',
      contents: 'folders/contents',
      folders: 'folders/folders',
      currentFolder: 'folders/currentFolder'
    }),
    rootHeight() {
      return this.innerHeight * (1 - (140 / this.innerHeight))
    },
    borderStyle() {
      return this.$vuetify.theme.dark
        ? 'transparent !important;'
        : 'thin solid rgba(0, 0, 0, 0.12) !important;'
    },
    navColor() {
      return !this.$vuetify.theme.dark
        ? '#F7F7F7'
        : ''
    },
    contentContainerHeight() {
      return !this.currentFolder ? 38 : 77;
    },
    contentPaginationHeight() {
      return this.currentFolder ? 'inherit' : 'auto';
    },
  },

  methods: {
    ...mapMutations({
      SET_CONTENTS: 'folders/setContents',
      SET_CURRENT_FOLDER: 'folders/setCurrentFolder',
      SET_FOLDERS: 'folders/setFolders'
    }),

    getPaginationCountData(entity = 'folders') {
      return {
        url: `${entity}/${this.user.id}/count`,
      }
    },

    getPaginationRequestData(entity = 'folders') {
      if (this.$route.query && this.$route.query.id) {
        return {
          url: `${entity}/${this.user.id}`,
          params: {
            query: {
              folder: {
                $eq: this.$route.query.id
              },
              deletedAt: {
                $eq: null
              },
            },
            sort: {
              createdAt: -1
            }
          }
        }
      } else {
        return {
          url: `${entity}/${this.user.id}`,
          params: {
            query: {
              sender: {
                $eq: this.myProfileId
              },
              deletedAt: {
                $eq: null
              },
              folder: {
                $exists: false
              }
            },
            sort: {
              createdAt: -1
            }
          }
        }
      }
    },

    async onDeleteContent() {
      await API().post(`content/delete/${this.user.id}`, {
        id: this.contentIdToDelete
      });
      this.openDeleteContentModalToggle()
      this.forceContentComponentReload()
    },


    forceContentComponentReload() {
      this.contentComponentKey += 1
    },
    
    forceFolderComponentReload() {
      this.folderComponentKey += 1
    },

    handleDeleteContent(contentId) {
      this.contentIdToDelete = contentId
      this.openDeleteContentModalToggle()
    },

    openDeleteContentModalToggle() {
      this.openDeleteContentModal = !this.openDeleteContentModal
    },
    
    openDeleteModalToggle() {
      this.openDeleteModal = !this.openDeleteModal
    },

    async onDeleteFolder() {
      const folderId = this.currentFolder.id
      await API().post(`folder/delete/${this.user.id}`, {
        id: folderId
      });
      this.SET_CURRENT_FOLDER(null)
      this.openDeleteModalToggle()
      this.$router.replace('/folders')
    },

    async setReferenceFolder(folder) {
      console.log("setReferenceFolder")
      // Loads the folder connector
      const [folderConnector] = await API().get('connectors', {
        params: {
          query: {
            type: {
              $eq: 'folder_sync'
            },
            entities: {
              $elemMatch: {
                ref: 'Persona',
                _id: ObjectID(folder.persona)
              }
            }
          }
        }
      });

      if (folderConnector) {
        const sourceContents = await API().get(`contents/${this.user.id}`, {
          params: {
            query: {
              folder: {
                $eq: folderConnector.entities[0]._id
              }
            },
            sort: {
              createdAt: -1
            }
          }
        })

        const referenceFolder = await API().get(`folder/${this.user.id}`, {
          params: {
            id: folderConnector.entities[0]._id
          }
        })

        return sourceContents.map(content => {
          return {
            ...content,
            referenceFolder: {
              id: referenceFolder.id,
              name: referenceFolder.name
            }
          }
        })
      } else return []
    },

    routeBack() {
      this.removeBreadcrumbs();
      if (this.currentFolder && this.currentFolder.folder)
        this.$router.replace(`/folders?id=${this.currentFolder.folder}`)
      else if (this.currentFolder)
        this.$router.replace('/folders')
      else
        this.$router.go(-1) 
    },

    // TODO: generalize to other types of content
    setActiveContent(content) {
      this.$router.push(`/pdf/read?id=${content._id}`)
    },

    /*
    * Bredcrumb handling
    */
    resetBreadcrumbs() {
      this.breadcrumbItems = [{
        text: 'My Library',
        disabled: false,
        to: '/folders'
      }]
    },

    removeBreadcrumbs() {
      this.breadcrumbItems = this.breadcrumbItems.slice(0, this.breadcrumbItems.length - 1)
    },
    
    async addBreadcrumbs(currentFolder, num = 1) {
      if (!currentFolder) return;

      this.breadcrumbItems.splice(1, 0, {
        id: currentFolder.id,
        text: currentFolder.name,
        disabled: false,
        to: `/folders?id=${currentFolder.id}`
      })

      if (currentFolder.folder) {
        const [parentFolder] = await API().get(`folders/${this.user.id}`, {
          params: {
            query: {
              _id: {
                $eq: currentFolder.folder
              }
            }
          }
        })
        
        if (num > 10) return; // force stop, just in case...
        if (parentFolder) this.addBreadcrumbs(parentFolder, num + 1)
        else return;
      } else return;
    },

    /*
    * Folder handling
    */
    close() {
      this.newFolder = false;
      this.editFolder = false;
      this.loading = true; 
      setTimeout(() => {
        this.loading = false
      })
    },

    setFolders(folders) {
      this.SET_FOLDERS(folders)
    },

    setContents(data) {
      this.SET_CONTENTS(data)
    },

    setCurrentFolder(folder) {
      this.SET_CURRENT_FOLDER(folder)
      if (folder) this.$router.replace(`/folders?id=${folder.id}`)
    },

    async addFolder(folderName) {
      const folder = await API().post(`folder/${this.user.id}`, {
        sender: this.myProfileId,
        name: folderName,
        permissions: {
          [this.myProfileId]: 'owner'
        },
        ...(this.currentFolder ? { folder: this.currentFolder.id } : {})
      })
      this.folders.unshift(folder)
      this.newFolder = false
    },

    async updateFolder(folderName) {
      await API().put(`folder/${this.user.id}`, {
        name: folderName
      }, {
        params: {
          id: this.currentFolder.id
        }
      })
      const itemIndex = this.breadcrumbItems.length - 1
      this.breadcrumbItems[itemIndex].text = folderName
      this.currentFolder.name = folderName
      this.editFolder = false
    },

    /*
    * Hover/Drag handling
    */

    /**
     * Handles breadcrumb hover event.
     * @param {Object} item - The breadcrumb item that is being hovered over.
     */
    onBreadcrumbHover(item) {
      // Set the breadcrumbHover property to the currently hovered breadcrumb item.
      this.breadcrumbHover = item;
    },

    /**
     * Handles breadcrumb mouse leave event.
     */
    onBreadcrumbLeave() {
      // Reset breadcrumbHover property to null when the mouse leaves the breadcrumb item.
      this.breadcrumbHover = null;
    },

    /**
     * Handles the start of a drag event.
     * @param {Object} ev - The event object associated with the drag start.
     */
    onDragStart(ev) {
      // Set the itemDragging property to the item being dragged.
      this.itemDragging = ev.item;
      // Set the dragging property to true indicating a drag action is in progress.
      this.dragging = true;
    },

    /**
     * Handles the end of a drag event.
     * @param {Object} ev - The event object associated with the drag end.
     */
    onDragEnd(ev) {
      // Set a timeout to wait for 10 milliseconds before setting itemDragging to null.
      // This is to ensure that any onBreadcrumbLeave event is handled before this.
      setTimeout(() => {
        this.itemDragging = null;
      }, 10);
      // Set dragging property to false indicating the drag action has ended.
      this.dragging = false;
      
      // Get the element where the drag ended
      // Use target in case toElement is not there
      const toElement = ev.originalEvent.toElement ?
        ev.originalEvent.toElement : ev.originalEvent.target;

      let folderId = null, backToTop = false;
      if (this.breadcrumbHover) {
        // Move up in the folder hierarchy
        folderId = this.breadcrumbHover.id
        // Cancel if moving to the same folder
        if (folderId === this.currentFolder.id) return;
        else if(!folderId) backToTop = true
       } else if (toElement.classList.contains('folder')) {
        // Handle case where the dragged item is dropped on an element with class 'folder'.
        folderId = toElement.id.split('_').pop()
      } else if (toElement.parentNode.classList.contains('folder')) {
        // Handle case where the dragged item is dropped on a child of an element with class 'folder'.
        folderId = toElement.parentNode.id.split('_').pop()
      } else if (toElement.parentNode.parentNode.classList.contains('folder')) {
        // Handle case where the dragged item is dropped on a grandchild of an element with class 'folder'.
        folderId = toElement.parentNode.parentNode.id.split('_').pop()
      }

      // for now handling the cases where there is a folderId
      // if there is no folderId, then either we are moving
      // to the top level 'My Library', or the user tried to move
      // into a ContentCard
      const [type, itemDraggingId] = this.itemDragging.id.split('_')

      // catch the edge case where we drag a folder to itself
      if (backToTop || folderId && folderId != itemDraggingId) {
        API().put(`${type}/${this.user.id}`, {
          ...(backToTop ? { $unset: { folder: '' } } : { folder: folderId }  )
        }, {
          params: {
            // note: you can only use more complex queries like unset
            // by NOT passing 'id', i.e., gotta pass _id
            // this is a shortcoming of the current backend model implementation
            _id: itemDraggingId
          }
        })

        if (type === 'folder') {
          const contentIndex = findIndex(this.folders, ({ id }) => id === itemDraggingId)
          this.folders.splice(contentIndex, 1)
        } else if (type === 'content') {
          const contentIndex = findIndex(this.contents, ({ id }) => id === itemDraggingId)
          this.contents.splice(contentIndex, 1)
        } // in case of bugs, do nothing
      }
    }
  }
}
</script>

<style>
.theme--dark .breadcrumb a {
  color: #FFAB00;
}
.breadcrumbs-container {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  max-width: 50%;  /* default to 50% for smaller screens */
}
.breadcrumbs-container .v-breadcrumbs {
  flex-wrap: nowrap;
}
.breadcrumbs-container::-webkit-scrollbar {
  height: 4px; /* or any other value */
}
.breadcrumbs-container {
  scrollbar-width: thin; /* or 'auto' or 'none' */
}
@media screen and (min-width: 1351px) {
  .breadcrumbs-container {
    max-width: 60%;  /* apply 60% max-width for screens larger than 1350px */
  }
}
</style>
