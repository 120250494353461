<template>
  <v-dialog
    v-model="dialogOn"
    max-width="400"
    @click:outside="close"
  >
    <v-card style="border-radius: 12px;">
      <v-container>
        <v-col class="py-0">
          <v-text-field
            filled
            placeholder="Title"
            class="hs-rounded-text"
            label="Folder name"
            :rules="[v => !!v || 'What is the folder name?']"
            v-model="folderName"
          />
        </v-col>        
      </v-container>

      <v-card-actions>
        <v-btn
          elevation="0"
          style="border-radius: 12px;"
          class="hs-rounded-lg"
          @click="close"
        >
          cancel
        </v-btn>

        <v-btn
          elevation="0"
          style="border-radius: 12px;"
          class="hs-rounded-lg"
          :disabled="!folderName"
          @click="setFolder"
        >
          {{ edit ? 'update' : 'create' }} folder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    newFolder: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dialogOn: false,
    folderName: ''
  }),

  computed: {
    ...mapGetters({
      currentFolder: 'folders/currentFolder'
    })
  },

  watch: {
    newFolder(v) {
      if (v) this.dialogOn = true
    },
    edit(v) {
      if (v) {
        if (this.currentFolder) this.folderName = this.currentFolder.name
        this.dialogOn = true
      }
    }
  },

  methods: {
    setFolder() {
      if (this.edit) this.$emit('updateFolder', this.folderName)
      else this.$emit('addFolder', this.folderName)
      this.close()
    },
    close() {
      this.$emit('close')
      this.folderName = ''
      this.dialogOn = false
    }
  }
}
</script>