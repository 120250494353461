<template>
  <v-card
    :elevation="hover && dragging ? elevation : 0"
    class="hs-rounded-lg clickable"
    style="transition: all 0.2s ease;"
    :color="cardColor"
    ripple
    @click.prevent="$emit('enter', folder)"
  >
    <v-list-item>
      <v-list-item-title>{{ folder.name }}</v-list-item-title>
      <v-list-item-action v-if="folder.persona">
        <v-icon>{{ mdiDramaMasks }}</v-icon>
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-icon>{{ mdiFolder }}</v-icon>
      </v-list-item-action>
    </v-list-item>          
  </v-card>
</template>

<script>
import { mdiFolder, mdiDramaMasks } from '@mdi/js'

export default {
  props: {
    dragging: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    folder: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    mdiDramaMasks,
    mdiFolder
  }),

  computed: {
    elevation() {
      return this.$vuetify.theme.dark ? 15 : 6
    },
    cardColor() {
      return this.$vuetify.theme.dark
        ? this.hover && !this.dragging ? '#000000' : '#151515'
        : this.hover && !this.dragging ? '#E0E0E0' : '#fdfdfd'
    }
  }
}
</script>