var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"height100",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"py-0 px-1 height100"},[_c('SnackBar',{attrs:{"snackMsg":_vm.snackMsg,"snackOn":_vm.snackOn},on:{"close":function($event){_vm.snackOn = false}}}),_c('NewFolder',{attrs:{"newFolder":_vm.newFolder,"edit":_vm.editFolder},on:{"addFolder":_vm.addFolder,"updateFolder":_vm.updateFolder,"close":_vm.close}}),_c('v-card',{style:(("\n        height: 100%;\n        width: 100%;\n        border: " + _vm.borderStyle + ";\n        overflow: hidden;\n      ")),attrs:{"outlined":"","rounded":"xl","color":_vm.navColor}},[_c('v-container',{staticClass:"py-2 height100"},[_c('v-row',{staticClass:"px-1 pr-2",attrs:{"align":"center","justify":"start"}},[_c('v-btn',{attrs:{"icon":"","disabled":this.from === '/'},on:{"click":_vm.routeBack}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiArrowLeft)+" ")])],1),_c('div',{staticClass:"breadcrumbs-container"},[_c('v-breadcrumbs',{staticClass:"py-0 breadcrumb",attrs:{"items":_vm.breadcrumbItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-breadcrumbs-item',{class:{
                    'clickable font-weight-bold': true,
                    'primary--text': !_vm.$vuetify.theme.dark,
                    'tertiary--text': _vm.$vuetify.theme.dark,
                  },on:{"click":function($event){return _vm.$router.replace(item.to)},"mouseover":function($event){return _vm.onBreadcrumbHover(item)},"mouseleave":_vm.onBreadcrumbLeave}},[_vm._v(" "+_vm._s(item.text.slice(0, 15) + (item.text.length > 15 ? '...' : ''))+" ")])]}}])})],1),_c('v-spacer'),_c('v-btn',{style:("border-radius: 12px;"),attrs:{"outlined":"","color":_vm.$vuetify.theme.dark ? 'tertiary' : '#6200EA'},on:{"click":function($event){_vm.newFolder = true}}},[_vm._v(" new folder ")]),_c('div',{staticClass:"mx-1"}),_c('v-btn',{style:(("border-radius: 12px;\n            background-color: " + (_vm.$vuetify.theme.dark
                ? 'rgba(255, 191, 16, 0.23)'
                : 'rgba(125, 33, 237, 0.157);'))),attrs:{"text":"","disabled":!_vm.currentFolder,"color":_vm.$vuetify.theme.dark ? 'tertiary' : '#6200EA'},on:{"click":function($event){_vm.editFolder = true}}},[_vm._v(" edit ")]),_c('div',{staticClass:"mx-1"}),(_vm.currentFolder && _vm.currentFolder.sender === _vm.myProfileId)?_c('v-btn',{style:("border-radius: 12px;\n            background-color: rgba(255, 0, 0, 0.32)"),attrs:{"icon":"","disabled":!_vm.currentFolder,"color":'red'},on:{"click":_vm.openDeleteModalToggle}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":300},on:{"click:outside":_vm.onClickOutside},model:{value:(_vm.openDeleteModal),callback:function ($$v) {_vm.openDeleteModal=$$v},expression:"openDeleteModal"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-container',[_c('v-row',{staticClass:"py-3",attrs:{"justify":"center"}},[_vm._v(" Are you sure? ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"hs-rounded-12",attrs:{"color":"primary"},on:{"click":function($event){_vm.openDeleteModal = false}}},[_vm._v(" cancel ")]),_c('div',{staticClass:"mx-1"}),_c('v-btn',{staticClass:"hs-rounded-12",attrs:{"text":"","color":"red"},on:{"click":_vm.onDeleteFolder}},[_vm._v(" delete ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":300},on:{"click:outside":_vm.onClickOutside},model:{value:(_vm.openDeleteContentModal),callback:function ($$v) {_vm.openDeleteContentModal=$$v},expression:"openDeleteContentModal"}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-container',[_c('v-row',{staticClass:"py-3",attrs:{"justify":"center"}},[_vm._v(" Are you sure? ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"hs-rounded-12",attrs:{"color":"primary"},on:{"click":function($event){_vm.openDeleteContentModal = false;
                      _vm.contentIdToDelete = null;}}},[_vm._v(" cancel ")]),_c('div',{staticClass:"mx-1"}),_c('v-btn',{staticClass:"hs-rounded-12",attrs:{"text":"","color":"red"},on:{"click":_vm.onDeleteContent}},[_vm._v(" delete ")])],1)],1)],1)],1)],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[(_vm.loading)?_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1),_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('ScrollPagination',{key:_vm.contentComponentKey,attrs:{"requestData":_vm.getPaginationRequestData('contents'),"countData":_vm.getPaginationCountData('contents'),"customPagination":{
            limit: 20,
            skip: 0,
            pageSize: 20,
          },"setFatherItems":_vm.setContents,"autoHide":"never","rootHeight":(_vm.rootHeight + "px"),"rootStyle":"align-items: baseline;","entityName":'content'},scopedSlots:_vm._u([{key:"staticSlot",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('v-container',[_c('v-row',{staticClass:"px-3",attrs:{"justify":"start"}},[_c('span',{staticClass:"text-overline"},[_vm._v(" Folders ")])]),(_vm.folders.length > 0)?_c('v-row',{staticClass:"px-3",attrs:{"justify":"start"}},_vm._l((_vm.folders),function(folder,index){return _c('v-col',{key:("col_folder_" + index),staticClass:"py-0 px-2",attrs:{"sm":"6","md":"6","lg":"4"}},[_c('draggable',{attrs:{"list":_vm.folders,"force-fallback":true,"sort":false},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd}},[_c('v-hover',{key:("folder_" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('FolderCard',{staticClass:"mb-4 folder",attrs:{"id":("folder_" + (folder ? folder.id : index)),"hover":hover,"dragging":_vm.dragging,"folder":folder},on:{"enter":_vm.setCurrentFolder}})]}}],null,true)})],1)],1)}),1):_vm._e()],1),_c('v-container',[_c('v-row',{staticClass:"px-3",attrs:{"justify":"start"}},[_c('span',{staticClass:"text-overline"},[_vm._v(" Contents ")])]),(_vm.folders.length + _vm.contents.length === 0)?_c('v-row',{staticClass:"px-3",attrs:{"justify":"start"}},[_c('span',{class:{
                    'text-subtitle-2 grey--text': true,
                    'text--lighten-1': _vm.$vuetify.theme.dark,
                    'text--darken-1': !_vm.$vuetify.theme.dark
                  }},[_vm._v(" Drag and drop your media here ")])]):_vm._e()],1)],1)]},proxy:true},{key:"child",fn:function(props){return [_c('v-col',{key:("col_content_" + (props.index)),attrs:{"sm":"4"}},[_c('draggable',{attrs:{"list":_vm.contents,"force-fallback":true,"sort":false},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd}},[_c('v-hover',{key:("content_" + (props.index)),scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('ContentCard',{staticClass:"content",attrs:{"id":("content_" + (props.item.id)),"idx":props.index,"content":props.item,"allowEdit":true,"type":"pdf","activeContentIdx":props.index,"hover":hover,"dragging":_vm.dragging},on:{"setActiveContent":function($event){return _vm.setActiveContent(props.item)}}})]}}],null,true)})],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }